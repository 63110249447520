<template>
  <div>
    <b-row>
      <b-col class="tex-left">
        <b-button
          id="toggle-btn"
          class="mb-1"
          variant="primary"
          @click="$router.push({ name: 'integracao-rede-damas' })"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          <span class="align-middle">Voltar</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="4"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->

              <!-- User Stats -->
              <b-row>
                <b-col md="12">
                  <div class="align-items-center ml-1">
                    <b-row>
                      <b-col
                        md="6"
                        class="mt-1"
                      >
                        <div class="d-flex align-items-center mr-2">
                          <b-avatar
                            variant="light-warning"
                            rounded
                          >
                            <feather-icon
                              icon="TrendingUpIcon"
                              size="18"
                            />
                          </b-avatar>
                          <div class="ml-1">
                            <h5 class="mb-0">
                              {{ operacoes.length }}
                            </h5>
                            <small>Operações</small>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="6"
                        class="mt-1"
                      >
                        <div class="d-flex align-items-center">
                          <b-avatar
                            variant="light-success"
                            rounded
                          >
                            <feather-icon
                              icon="DollarSignIcon"
                              size="18"
                            />
                          </b-avatar>
                          <div class="ml-1">
                            <h5 class="mb-0">
                              {{ valorBr(parseFloat(totalVoz), true) }}
                            </h5>
                            <small>Total Voz</small>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        md="6"
                        class="mt-1"
                      >
                        <div class="d-flex align-items-center">
                          <b-avatar
                            variant="light-success"
                            rounded
                          >
                            <feather-icon
                              icon="DollarSignIcon"
                              size="18"
                            />
                          </b-avatar>
                          <div class="ml-1">
                            <h5 class="mb-0">
                              {{ valorBr(parseFloat(totalRealizado), true) }}
                            </h5>
                            <small>Total Realizado</small>
                          </div>
                        </div>
                      </b-col>

                      <b-col
                        md="6"
                        class="mt-1"
                      >
                        <div class="d-flex align-items-center">
                          <b-avatar
                            variant="light-success"
                            rounded
                          >
                            <feather-icon
                              icon="DollarSignIcon"
                              size="18"
                            />
                          </b-avatar>
                          <div class="ml-1">
                            <h5 class="mb-0">
                              {{ valorBr(parseFloat(totalRepasse), true) }}
                            </h5>
                            <small>Total Repasse</small>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col class="text-left mt-2">
                        <b-button
                          id="toggle-btn"
                          class="mb-1"
                          size="sm"
                          variant="outline-warning"
                          @click="integrarAric"
                        >
                          <span class="align-middle">Integrar</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Credor</span>
                  </th>
                  <td class="pb-50">
                    <b-badge variant="warning">
                      {{ dadosEquivalentes.credor }}
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="WatchIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Data Remessa</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ dadosEquivalentes.dataRemessa }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="WatchIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Data Pagamento</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ dadosEquivalentes.dataPagamento }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="WatchIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Forma Pagamento</span>
                  </th>
                  <td class="pb-50">
                    {{ dadosEquivalentes.formaPagamento }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="WatchIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Banco</span>
                  </th>
                  <td class="pb-50">
                    {{ dadosEquivalentes.banco }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-row>
                <b-col class="text-center">
                  <h5>{{ bar.label }}</h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-progress
                    :key="bar.variant"
                    animated
                    :value="bar.value"
                    :variant="bar.variant"
                    :class="'progress-bar-' + bar.variant"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-table
            hover
            striped
            responsive
            fixed
            :fields="fields"
            :items="operacoes"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width:
                    field.key === 'origem' || field.key === 'cliente'
                      ? '270px'
                      : '180px',
                }"
              >
            </template>
            <template #cell(cliente)="data">
              {{ fisrtLastName(data.item.cliente) }}
            </template>

            <template #cell(num_base)="data">
              <div v-if="data.item.num_base">
                {{ data.item.num_base }}
              </div>
              <div v-else>
                <b-badge variant="danger">
                  Pendente
                </b-badge>
              </div>
            </template>

            <template #cell(num_documento)="data">
              <div v-if="data.item.num_documento">
                {{ data.item.num_documento }}
              </div>
              <div v-else>
                <b-badge variant="danger">
                  Pendente
                </b-badge>
              </div>
            </template>

            <template #cell(vencimento)="data">
              {{ americaDate(data.item.vencimento) }}
            </template>

            <template #cell(valor_principal)="data">
              {{ valorBr(parseFloat(data.item.valor_principal), true) }}
            </template>

            <template #cell(valor_realizado)="data">
              {{ valorBr(parseFloat(data.item.valor_realizado), true) }}
            </template>

            <template #cell(valor_repasse)="data">
              {{ valorBr(parseFloat(data.item.valor_repasse), true) }}
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  BProgress,
} from 'bootstrap-vue'
import api from 'axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    BProgress,
  },
  data() {
    return {
      endPoint: 'http://192.168.254.204:9000',
      banco: null,
      pagamento: null,
      filial: null,

      fields: [
        { label: 'Operação', key: 'num_operacao' },
        { label: 'Referênica', key: 'referencia' },
        { label: 'Documento', key: 'num_documento' },
        { label: 'Vencimento', key: 'vencimento' },
        { label: 'Cliente', key: 'cliente' },
        { label: 'CPF/CNPJ', key: 'cpfcnpj' },
        { label: 'Orígem', key: 'origem' },
        { label: 'Parcela', key: 'parcela', class: 'text-center' },
        { label: 'Juros', key: 'valor_juros' },
        { label: 'Multa', key: 'valor_multa' },
        { label: 'Hon. Variável', key: 'valor_honorarios' },
        { label: 'Realizado', key: 'valor_realizado' },
        { label: 'Principal', key: 'valor_principal' },
        { label: 'Repasse', key: 'valor_repasse' },
      ],
      operacoes: [],
      totalVoz: 0,
      totalRealizado: 0,
      totalRepasse: 0,

      dadosEquivalentes: {
        credor: null,
        dataRemessa: null,
        dataPagamento: null,
        formaPagamento: null,
        banco: null,
      },

      bar: {
        label: '',
        value: 0,
        variant: 'success',
      },
    }
  },
  async created() {
    const { banco, pagamento, filial } = this.$route.params
    this.banco = banco
    this.pagamento = pagamento
    this.filial = filial
    await this.getRemessa()
  },

  methods: {
    async getRemessa() {
      const { banco, pagamento } = this
      if (banco && pagamento) {
        const data = new FormData()
        data.append('banco', banco)
        data.append('pagamento', pagamento)
        await api
          .post(
            `${this.endPoint}/api/planilha/operacao`,
            data,
          )
          .then(res => {
            const { data: items } = res
            const operacoes = []
            const DadosEquivalente = {
              credor: null,
              dataRemessa: null,
              dataPagamento: null,
              formaPagamento: null,
              banco: null,
            }
            let totalVoz = 0
            let totalRealizado = 0
            let totalRepasse = 0
            items.map(item => {
              operacoes.unshift(item)
              DadosEquivalente.credor = item.unidade
              DadosEquivalente.dataRemessa = this.dataHora(
                item.created_at,
                true,
              )
              DadosEquivalente.formaPagamento = item.forma_pagamento
              DadosEquivalente.dataPagamento = this.americaDate(item.pagamento)
              DadosEquivalente.banco = item.meio_pagamento
              totalVoz += parseFloat(item.valor_honorarios)
              totalRealizado += parseFloat(item.valor_realizado)
              totalRepasse += parseFloat(item.valor_repasse)
            })

            this.totalVoz = totalVoz.toFixed(2)
            this.totalRealizado = totalRealizado.toFixed(2)
            this.totalRepasse = totalRepasse.toFixed(2)

            this.dadosEquivalentes = DadosEquivalente

            this.operacoes = operacoes
          })
      }
    },

    async desbloqueioTitulos() {
      const { operacoes, filial } = this
      this.bar.label = 'DESBLOQUEANDO TÍTULOS'
      let count = 0
      await operacoes.map(async op => {
        const body = new FormData()
        body.append('id_lan', op.num_operacao)
        body.append('filial', filial)
        await api.post(
          `${this.endPoint}/api/process/FinLanDesbloqueiaBaixaData`,
          body,
          { timeout: 300 * 1000 },
        ).then(() => {
          count += 1
          if (count === operacoes.length) {
            this.lancarUnidades()
          }
        })
      })
      this.bar.value = 25
    },

    async lancarUnidades() {
      const {
        banco, pagamento,
      } = this
      const data = new FormData()
      data.append('banco', banco)
      data.append('pagamento', pagamento)

      // Lançando
      this.bar.label = 'LANÇANDO UNIDADES'
      await api.post(
        `${this.endPoint}/api/cadastroLancamentoUnidade`,
        data,
      ).then(() => {
        this.bar.value = 50
        this.fazerAcordo()
      })
    },

    async fazerAcordo() {
      const { operacoes } = this
      this.bar.value = 55
      const alunos = []
      const alunosCheck = {}
      await operacoes.map(op => {
        alunos.push(op.nome_contrato)
      })

      this.bar.value = 60
      const unique = [...new Set(alunos)]
      unique.map(item => {
        alunosCheck[item] = false
      })

      let count = 0

      this.bar.label = 'GERANDO ACORDO NA BASE'
      unique.map(aluno => {
        operacoes.map((op, index) => {
          setTimeout(async () => {
            if (op.nome_contrato === aluno && !alunosCheck[aluno]) {
              const acordo = {
                aluno,
                pagamento: op.pagamento,
              }
              alunosCheck[aluno] = true
              await api
                .post(
                  `${this.endPoint}/api/process/FinAcordoGeracaoProcData`,
                  acordo,
                )
                .then(() => {
                  count += 1
                  if (count === unique.length) {
                    this.getReferencias()
                  }
                })
            }
          }, 5000 * index)
        })
      })
      this.bar.value = 75
    },

    async getReferencias() {
      const {
        banco, pagamento,
      } = this
      const data = new FormData()
      data.append('banco', banco)
      data.append('pagamento', pagamento)
      setTimeout(async () => {
        await api.post(`${this.endPoint}/api/atualizar`, data).then(() => {
          this.bar.value = 80
          setTimeout(async () => {
            await this.getRemessa()
            await this.fazerBaixa()
          }, 1500)
        }, 1200)
      })
    },

    async fazerBaixa() {
      const { operacoes } = this
      let referencias = []
      operacoes.map(item => {
        referencias.push(item.referencia)
      })
      this.bar.value = 90

      referencias = [...new Set(referencias)]

      this.bar.label = 'REALIZANDO BAIXA'
      referencias.map(async item => {
        await api
          .post(
            `${this.endPoint}/api/process/FinLanBaixaData`,
            {
              lancamento: item,
            },
          )
          .then(() => {
          })
      })
      this.bar.value = 100
      this.bar.label = 'CONCLUÍDO'

      setTimeout(() => {
        this.bar.value = 0
        this.bar.label = ''
      }, 1500)
    },

    async integrarAric() {
      const {
        banco, pagamento, filial,
      } = this

      if (banco && pagamento && filial) {
        this.desbloqueioTitulos()
      }
    },
  },
}
</script>
